import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("New group")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onKeyup: _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.createFacilityGroup && _ctx.createFacilityGroup(...args)), ["enter"]))
        }, [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    "label-placement": "floating",
                    onIonBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setFacilityGroupId($event))),
                    modelValue: _ctx.formData.facilityGroupName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.facilityGroupName) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createTextVNode(_toDisplayString(_ctx.translate("Name")) + " ", 1),
                        _createVNode(_component_ion_text, { color: "danger" }, {
                          default: _withCtx(() => [
                            _createTextVNode("*")
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    "label-placement": "floating",
                    label: _ctx.translate('Internal ID'),
                    ref: "facilityGroupId",
                    modelValue: _ctx.formData.facilityGroupId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.facilityGroupId) = $event)),
                    onIonInput: _ctx.validateFacilityGroupId,
                    onIonBlur: _ctx.markFacilityGroupIdTouched,
                    "error-text": _ctx.translate('Internal ID cannot be more than 20 characters.')
                  }, null, 8, ["label", "modelValue", "onIonInput", "onIonBlur", "error-text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    label: _ctx.translate('System group type'),
                    disabled: _ctx.isFacilityGroupTypeDisabled,
                    interface: "popover",
                    modelValue: _ctx.formData.facilityGroupTypeId,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.facilityGroupTypeId) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityGroupTypes, (facilityGroupType) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          value: facilityGroupType.facilityGroupTypeId,
                          key: facilityGroupType.facilityGroupTypeId
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(facilityGroupType.description ?  facilityGroupType.description : facilityGroupType.facilityGroupTypeId), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label", "disabled", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate('Description'),
                    "label-placement": "floating",
                    modelValue: _ctx.formData.description,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.description) = $event))
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _ctx.createFacilityGroup,
                onKeyup: _cache[6] || (_cache[6] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    })
  ], 64))
}