import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bd0c5db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Custom schedule")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_input, {
              modelValue: _ctx.selectedTimesForWeek.description,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTimesForWeek.description) = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createTextVNode(_toDisplayString(_ctx.translate("Name")) + " ", 1),
                  _createVNode(_component_ion_text, { color: "danger" }, {
                    default: _withCtx(() => [
                      _createTextVNode("*")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, {
          lines: "full",
          class: "ion-margin-top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toggle, {
              checked: _ctx.isDailyTimingsChecked,
              onIonChange: _ctx.updateDailyTimings
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Daily timings")), 1)
              ]),
              _: 1
            }, 8, ["checked", "onIonChange"])
          ]),
          _: 1
        }),
        (_ctx.isDailyTimingsChecked)
          ? (_openBlock(), _createBlock(_component_ion_list, {
              key: 0,
              lines: "none"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate(day.charAt(0).toUpperCase() + day.slice(1))), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_chip, {
                        onClick: ($event: any) => (_ctx.openTimeModal(day+'StartTime'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.selectedTimesForWeek[day+'StartTime'] ? _ctx.getTime(_ctx.selectedTimesForWeek[day+'StartTime']) : 'Start Time') + " ", 1),
                          (_ctx.selectedTimesForWeek[day+'StartTime'])
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                icon: _ctx.closeCircle,
                                onClick: [
                                  ($event: any) => (_ctx.clearSelectedTime(day+'StartTime')),
                                  _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                                ]
                              }, null, 8, ["icon", "onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createTextVNode(" - "),
                      _createVNode(_component_ion_chip, {
                        onClick: ($event: any) => (_ctx.openTimeModal(day+'EndTime'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.selectedTimesForWeek[day+'EndTime'] ? _ctx.getTime(_ctx.selectedTimesForWeek[day+'EndTime']) : 'End Time') + " ", 1),
                          (_ctx.selectedTimesForWeek[day+'EndTime'])
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                icon: _ctx.closeCircle,
                                onClick: [
                                  ($event: any) => (_ctx.clearSelectedTime(day+'EndTime')),
                                  _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                                ]
                              }, null, 8, ["icon", "onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("Open and close time")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_chip, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openTimeModal('DailyStartTime')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedTimesForWeek['DailyStartTime'] ? _ctx.getTime(_ctx.selectedTimesForWeek['DailyStartTime']) : 'Start Time') + " ", 1),
                        (_ctx.selectedTimesForWeek['DailyStartTime'])
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.closeCircle,
                              onClick: [
                                _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearSelectedTime('DailyStartTime'))),
                                _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                              ]
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" - "),
                    _createVNode(_component_ion_chip, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openTimeModal('DailyEndTime')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedTimesForWeek['DailyEndTime'] ? _ctx.getTime(_ctx.selectedTimesForWeek['DailyEndTime']) : 'End Time') + " ", 1),
                        (_ctx.selectedTimesForWeek['DailyEndTime'])
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.closeCircle,
                              onClick: [
                                _cache[6] || (_cache[6] = ($event: any) => (_ctx.clearSelectedTime('DailyEndTime'))),
                                _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
                              ]
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      class: "date-time-modal",
      "is-open": _ctx.isTimeModalOpen,
      onDidDismiss: _cache[10] || (_cache[10] = () => _ctx.isTimeModalOpen = false)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_datetime, {
          "show-default-buttons": "",
          "hour-cycle": "h12",
          presentation: "time",
          value: _ctx.selectedTimesForWeek[_ctx.selectedDayTime] ? _ctx.selectedTimesForWeek[_ctx.selectedDayTime] : _ctx.DateTime.now().toUTC().toISO(),
          onIonChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateTime($event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          disabled: !Object.keys(_ctx.selectedTimesForWeek).length || !_ctx.selectedTimesForWeek.description,
          onClick: _ctx.saveCustomSchedule
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    })
  ], 64))
}