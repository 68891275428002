import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0909ad58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "find" }
const _hoisted_2 = { class: "ion-padding-end search" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ion-margin-start" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "empty-state" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/find-groups",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Manage Facilities")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_component_ion_searchbar, {
                placeholder: _ctx.translate('Search facilities'),
                modelValue: _ctx.queryString,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
                onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getFilteredFacilities()))
              }, null, 8, ["placeholder", "modelValue"]),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('Results')) + " : " + _toDisplayString(_ctx.filteredFacilities?.length), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        fill: "clear",
                        onClick: _ctx.selectAll,
                        disabled: !_ctx.filteredFacilities.length
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('Include all')) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowForwardOutline }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"])
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFacilities, (facility) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: facility.facilityId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(facility.facilityId), 1),
                            _createTextVNode(" " + _toDisplayString(facility.facilityName), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_button, {
                          slot: "end",
                          fill: "clear",
                          color: "success",
                          onClick: ($event: any) => (_ctx.selectFacility(facility))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.addCircleOutline,
                              slot: "icon-only"
                            }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      (!_ctx.filteredFacilities.length)
                        ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate('No facilities available to select')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            (_ctx.selectedFacilities.length)
              ? (_openBlock(), _createElementBlock("main", _hoisted_3, [
                  _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('Total facilities selected for group', {total: _ctx.selectedFacilities.length, facilityGroupName: _ctx.currentFacilityGroup.facilityGroupName ? _ctx.currentFacilityGroup.facilityGroupName : _ctx.facilityGroupId})), 1),
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate('Manage sequence')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_reorder_group, {
                        onIonItemReorder: _cache[2] || (_cache[2] = ($event: any) => (_ctx.doReorder($event))),
                        disabled: false
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFacilities, (facility) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: facility.facilityId
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  slot: "start",
                                  fill: "clear",
                                  color: "danger",
                                  onClick: ($event: any) => (_ctx.removeFacility(facility))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.removeCircleOutline,
                                      slot: "icon-only"
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", null, _toDisplayString(facility.facilityId), 1),
                                    _createTextVNode(" " + _toDisplayString(facility.facilityName), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_reorder, { slot: "end" })
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("main", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.translate('No facilities selected.')), 1)
                ]))
          ]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                disabled: !_ctx.isFacilityMembersModified,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}