import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }
const _hoisted_4 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Location")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onKeyup: _cache[9] || (_cache[9] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveFacilityLocation && _ctx.saveFacilityLocation(...args)), ["enter"]))
        }, [
          _createVNode(_component_ion_item, {
            onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                "label-placement": "floating",
                label: _ctx.translate('Type'),
                interface: "popover",
                placeholder: _ctx.translate('Select'),
                modelValue: _ctx.locationInfo.locationTypeEnumId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.locationInfo.locationTypeEnumId) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationTypes, (description, type) => {
                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                      key: type,
                      value: type
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(description), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["label", "placeholder", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.locationInfo.areaId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.locationInfo.areaId) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Area")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.locationInfo.aisleId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.locationInfo.aisleId) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Aisle")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.locationInfo.sectionId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.locationInfo.sectionId) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Section")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.locationInfo.levelId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.locationInfo.levelId) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Level")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: _ctx.translate('Sequence'),
                "label-placement": "floating",
                modelValue: _ctx.locationInfo.positionId,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.locationInfo.positionId) = $event))
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _ctx.saveFacilityLocation,
                onKeyup: _cache[8] || (_cache[8] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    })
  ], 64))
}