import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ion-text-center ion-padding-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Consumed Order Limit")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.facilityOrderCounts.length && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Entry Date')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Consumed Order Limit')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityOrderCounts, (facilityOrderCount) => {
                  return (_openBlock(), _createBlock(_component_ion_row, {
                    class: "ion-justify-content-center",
                    key: facilityOrderCount.facilityId
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(facilityOrderCount.entryDate), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(facilityOrderCount.lastOrderCount), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate('No records found')), 1))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}