import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            button: "",
            disabled: !_ctx.isRegenerationRequired,
            onClick: _ctx.regenerateLatitudeAndLongitude
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Regenerate")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_ion_item, {
            button: "",
            lines: "none",
            onClick: _ctx.removeLatitudeAndLongitude
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Remove")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}