import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76ae6627"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ion-padding",
  slot: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Add operating hours")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_accordion_group, {
          modelValue: _ctx.selectedCalendarId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCalendarId) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio_group, {
              modelValue: _ctx.selectedCalendarId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCalendarId) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendars, (calendar) => {
                  return (_openBlock(), _createBlock(_component_ion_accordion, {
                    key: calendar.calendarId,
                    value: calendar.calendarId
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, { slot: "header" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_radio, {
                            "label-placement": "end",
                            justify: "start",
                            value: calendar.calendarId
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(calendar.description ? calendar.description : calendar.calendarId), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["value"])
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_ion_list, { lines: "none" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day) => {
                              return (_openBlock(), _createBlock(_component_ion_item, { key: day }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(_ctx.translate(day.charAt(0).toUpperCase() + day.slice(1))), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(calendar[day+'StartTime'] ? _ctx.getStartAndEndTime(calendar[day+'StartTime'], calendar[day+'Capacity']) : _ctx.translate('Closed')), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          disabled: !_ctx.selectedCalendarId || _ctx.facilityCalendar.calendarId === _ctx.selectedCalendarId,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveOperatingHours()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}