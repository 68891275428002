import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ad25202"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Staff")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryString) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.findParties()}, ["enter"]))
        }, null, 8, ["modelValue"]),
        (!_ctx.parties.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate("No party found")), 1))
          : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Staff")), 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parties, (party, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        placeholder: _ctx.translate('Select'),
                        value: _ctx.getPartyRoleTypeId(party.partyId),
                        onIonChange: ($event: any) => (_ctx.updateSelectedParties($event, party.partyId)),
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { slot: "label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(party.fullName) + " ", 1),
                              _createElementVNode("p", null, _toDisplayString(party.partyId), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyRoles, (description, roleTypeId) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: roleTypeId,
                              value: roleTypeId
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(description), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["placeholder", "value", "onIonChange"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          disabled: !_ctx.isRoleUpdated(),
          onClick: _ctx.saveParties
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    })
  ], 64))
}