import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ion-text-wrap",
  slot: "label"
}
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate(`Create ${_ctx.facilityTypeDesc} login`)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  "label-placement": "floating",
                  modelValue: _ctx.username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createTextVNode(_toDisplayString(_ctx.translate('Username')) + " ", 1),
                      _createVNode(_component_ion_text, { color: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode("*")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              ref: "password",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  "label-placement": "floating",
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  onKeyup: _ctx.validatePassword,
                  onIonBlur: _ctx.markPasswordTouched,
                  type: "password",
                  helperText: _ctx.translate('Password should be at least 5 characters long, it contains at least one number, one alphabet and one special character.')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.translate('Password')) + " ", 1),
                      _createVNode(_component_ion_text, { color: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode("*")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "onKeyup", "onIonBlur", "helperText"])
              ]),
              _: 1
            }, 512),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  "label-placement": "floating",
                  modelValue: _ctx.emailAddress,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailAddress) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createTextVNode(_toDisplayString(_ctx.translate('Reset password email')) + " ", 1),
                      _createVNode(_component_ion_text, { color: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode("*")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              disabled: _ctx.checkCreateUserButtonStatus(),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createFacilityLogin()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.lockClosedOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}