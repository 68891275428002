import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b839d37c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }
const _hoisted_4 = { class: "ion-text-center ion-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/find-facilities",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Add Store Address")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Address')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Shipping name'),
                          "label-placement": "floating",
                          modelValue: _ctx.formData.toName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.toName) = $event))
                        }, null, 8, ["label", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          "label-placement": "floating",
                          modelValue: _ctx.formData.address1,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.address1) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createTextVNode(_toDisplayString(_ctx.translate('Address line 1')) + " ", 1),
                              _createVNode(_component_ion_text, { color: "danger" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("*")
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Address line 2'),
                          "label-placement": "floating",
                          modelValue: _ctx.formData.address2,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.address2) = $event))
                        }, null, 8, ["label", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          "label-placement": "floating",
                          modelValue: _ctx.formData.city,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.city) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createTextVNode(_toDisplayString(_ctx.translate('City')) + " ", 1),
                              _createVNode(_component_ion_text, { color: "danger" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("*")
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          "label-placement": "floating",
                          modelValue: _ctx.formData.postalCode,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.postalCode) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createTextVNode(_toDisplayString(_ctx.translate('Zipcode')) + " ", 1),
                              _createVNode(_component_ion_text, { color: "danger" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("*")
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          label: _ctx.translate('Country'),
                          "label-placement": "floating",
                          interface: "popover",
                          placeholder: _ctx.translate('Select country'),
                          onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateState($event))),
                          modelValue: _ctx.formData.countryGeoId,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.countryGeoId) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: country.geoId,
                                value: country.geoId
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(country.geoName), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["label", "placeholder", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          label: _ctx.translate('State'),
                          "label-placement": "floating",
                          interface: "popover",
                          disabled: !_ctx.formData.countryGeoId,
                          placeholder: _ctx.translate('Select state'),
                          modelValue: _ctx.formData.stateProvinceGeoId,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.stateProvinceGeoId) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states[_ctx.formData.countryGeoId], (state) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: state.geoId,
                                value: state.geoId
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(state.wellKnownText && state.wellKnownText !== state.geoName ? `${state.geoName} (${state.wellKnownText})` : state.geoName), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["label", "disabled", "placeholder", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Contact number'),
                          "label-placement": _ctx.countryCode ? 'stacked' : 'floating',
                          modelValue: _ctx.contactNumber,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.contactNumber) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.countryCode)
                              ? (_openBlock(), _createBlock(_component_ion_text, {
                                  key: 0,
                                  slot: "start"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.countryCode), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["label", "label-placement", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Latitude & Longitude')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.formData.postalCode,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.postalCode) = $event)),
                          placeholder: _ctx.translate('Zipcode')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              disabled: !_ctx.formData.postalCode || !_ctx.formData.address1 || !_ctx.formData.city,
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.generateLatLong())),
                              slot: "end",
                              fill: "outline"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "end",
                                  icon: _ctx.colorWandOutline
                                }, null, 8, ["icon"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate('Generate')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Latitude'),
                          "label-placement": "floating",
                          disabled: !_ctx.formData.address1 || !_ctx.formData.city,
                          modelValue: _ctx.formData.latitude,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.latitude) = $event))
                        }, null, 8, ["label", "disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Longitude'),
                          "label-placement": "floating",
                          disabled: !_ctx.formData.address1 || !_ctx.formData.city,
                          modelValue: _ctx.formData.longitude,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData.longitude) = $event))
                        }, null, 8, ["label", "disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_button, {
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.addAddress()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.locationOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate("Save address")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.router.replace(`/add-facility-config/${_ctx.facilityId}`))),
                color: "medium",
                fill: "clear"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Add address later")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}