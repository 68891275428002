import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d04b4c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { class: "ion-text-center ion-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/find-facilities",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Add Store")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Setup Store')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          label: _ctx.translate('Type'),
                          interface: "popover",
                          modelValue: _ctx.selectedFacilityTypeId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFacilityTypeId) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityTypesByParentTypeId, (type, facilityTypeId) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                value: facilityTypeId,
                                key: facilityTypeId
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(type.description ? type.description : facilityTypeId), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["label", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          "label-placement": "floating",
                          onIonBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setFacilityId($event))),
                          modelValue: _ctx.formData.facilityName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.facilityName) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createTextVNode(_toDisplayString(_ctx.translate('Name')) + " ", 1),
                              _createVNode(_component_ion_text, { color: "danger" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("*")
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Internal ID'),
                          "label-placement": "floating",
                          ref: "facilityId",
                          modelValue: _ctx.formData.facilityId,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.facilityId) = $event)),
                          onIonChange: _ctx.validateFacilityId,
                          onIonBlur: _ctx.markFacilityIdTouched,
                          "error-text": "translate('Internal ID cannot be more than 20 characters.')"
                        }, null, 8, ["label", "modelValue", "onIonChange", "onIonBlur"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('External ID'),
                          "label-placement": "floating",
                          modelValue: _ctx.formData.externalId,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.externalId) = $event))
                        }, null, 8, ["label", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createFacility()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.addOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.facilityTypes[_ctx.selectedFacilityTypeId]?.description ? _ctx.translate(`Create ${_ctx.facilityTypes[_ctx.selectedFacilityTypeId].description}`) : _ctx.translate(`Create ${_ctx.selectedFacilityTypeId}`)), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}